import React, { Component } from 'react';

import Page from './Page';

import 'bootstrap/dist/css/bootstrap.css';
import '../../sass/app.scss';

class App extends Component {
  render() {
    return (
        <Page />
    )
  }
};

export default App;
